<template>
    <div>

    </div>
</template>

<script>
import router from '@/router'
export default {
    data() {
        return {
            token: '',
            user_id: '',
        }
    },
    methods: {
        user_auth_with_token() {
            this.$axios
                .post(`auth/login-with-token/${this.token}/${this.user_id}`)
                .then((response) => {
                    const token = response.data.access_token;
                    const user = response.data.authUser.user;
                    const userRole = response.data.authUser.roleUser[0];
                    let userRoleLm = false;
                    let userRoleHr = false;
                    for (let i = 0; i < response.data.authUser.roleUser.length; i++) {
                        let role = response.data.authUser.roleUser[i];
                        if (role == 'linemanager') {
                            userRoleLm = true;
                        }
                        if (role == 'hrmanager') {
                            userRoleHr = true;
                        }
                    }
                    const userPermission = response.data.authUser.permissionUsers;
                    const statusChangePassword = response.data.authUser.user.status_change_password;
                    localStorage.setItem("synergy_user", JSON.stringify(user));
                    localStorage.setItem("userPermission", JSON.stringify(userPermission));
                    localStorage.setItem("statusChangePassword", statusChangePassword);
                    localStorage.setItem('access_token', token); // ເກັບ Token ໄວ້ໃນ Localstorage ເພື່ອຈະນຳໄປໃຊ້ຂໍຂໍ້ມູນ
                    // context.commit('AdminSigIn', token);
                    // context.commit("statusChangePassword", statusChangePassword)
                    // context.commit('setUserProfile', userRole);
                    window.localStorage.setItem('user_role', JSON.stringify(userRole));
                    window.localStorage.setItem('user_role_lm', userRoleLm);
                    window.localStorage.setItem('user_role_hr', userRoleHr);
                    this.$axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                    switch (userRole) {
                        case 'superadmin':
                            router.push({ name: "Dashboard" })
                            break;
                        case 'synergyadmin':
                            router.push({ name: "Dashboard" });
                            break;
                        case 'synergyuser':
                            router.push({ name: "Dashboard" });
                            break;

                        case 'companyadmin':
                            router.push({ name: "CompanyDashboard" });
                            break;

                        case 'companyuser':
                            router.push({ name: "CompanyDashboard" });
                            break;
                        case 'employee':
                            console.log('Role: ' + userRole)
                            router.push({ name: "EmployeeDashboard" });
                            break;
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                });
        }
    },

    created() {
        this.token = this.$route.params.token;
        this.user_id = this.$route.params.user_id;
        this.user_auth_with_token();
    },
}
</script>
