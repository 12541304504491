<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form">
          <v-row class="row-center">
            <v-col cols="8" md="8">
              <div class="image-container">
                <div class="image-company">
                  <img :src="showImage" alt="" />
                  <div class="image-button">
                    <div class="btn-img">
                      <i class="fas fa-camera"></i>
                      <input
                        @change="PreviewImage"
                        class="file-input"
                        type="file"
                        name="resume"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                ref="input-0"
                @keydown.enter="focusNextInput"
                dense
                outlined
                :label="$t('Company.table.name')"
                v-model="data.name"
                :hint="`${server_errors.name}`"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                class="input-number"
                @keydown.enter="focusNextInput"
                dense
                outlined
                type="number"
                :label="$t('Company.table.phone')"
                v-model="data.phone"
                :hint="`${server_errors.phone}`"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                @keydown.enter="focusNextInput"
                dense
                outlined
                :label="$t('Company.table.email')"
                v-model="data.email"
                :hint="`${server_errors.email}`"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                @keydown.enter="focusNextInput"
                dense
                outlined
                :label="$t('Company.table.address')"
                v-model="data.address"
                :hint="`${server_errors.address}`"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-select
                dense
                outlined
                :label="$t('Company.table.businessType')"
                :items="businessType"
                item-text="name"
                item-value="id"
                v-model="data.type_business_id"
                :hint="`${server_errors.type_business_id}`"
                persistent-hint
              >
              </v-select>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <DatePicker
                class="date-time"
                style="width: 100%"
                type="date"
                v-model="data.founding_date"
                valueType="format"
                slot="activator"
                :append-to-body="true"
                name="founding_date"
                :placeholder="$t('Company.table.foundingDate')"
              ></DatePicker>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <label class="label-input">{{ $t("Company.vat") }}</label>
              <v-radio-group row v-model="data.charge_vat">
                <v-radio
                  class="label-input"
                  value="yes"
                  :label="$t('Company.vat_yes')"
                >
                </v-radio>
                <v-radio
                  class="label-input"
                  value="no"
                  :label="$t('Company.vat_no')"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <label class="label-input">{{ $t("Company.clock_action.title") }}</label>
              <v-radio-group row v-model="data.edit_clock_in_out">
                <v-radio
                  class="label-input"
                  value="yes"
                  :label="$t('Company.clock_action.yes')"
                >
                </v-radio>
                <v-radio
                  class="label-input"
                  value="no"
                  :label="$t('Company.clock_action.no')"
                >
                </v-radio>
              </v-radio-group>
            </v-col>

            <!--            <v-col cols="6" md="6" lg="6">-->
            <!--              <label class="label-input">{{ $t("Company.tax") }}</label>-->
            <!--              <v-radio-group row v-model="data.include_salary_tax">-->
            <!--                <v-radio-->
            <!--                  class="label-input"-->
            <!--                  value="yes"-->
            <!--                  :label="$t('Company.tax_yes')"-->
            <!--                >-->
            <!--                </v-radio>-->
            <!--                <v-radio-->
            <!--                  class="label-input"-->
            <!--                  value="no"-->
            <!--                  :label="$t('Company.tax_no')"-->
            <!--                >-->
            <!--                </v-radio>-->
            <!--              </v-radio-group>-->
            <!--            </v-col>-->

            <v-col cols="12" md="12" lg="12" :style="{paddingTop:'1rem'}">
              <v-btn
                class="btn-save-change"
                @click="ValidateForm"
                :loading="btnLoading"
              >
                {{ $t("Company.action.saveChange") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  component: {
    DatePicker,
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      modal: false,
      businessType: [],
      showImage: "",
      btnLoading: false,
      imageFile: null,
      data: {
        name: "",
        phone: "",
        email: "",
        address: "",
        type_business_id: "",
        founding_date: "",
        logo: "",
        charge_vat: "",
        include_salary_tax: "",
        edit_clock_in_out: ""
      },
      server_errors: {
        name: "",
        phone: "",
        email: "",
        address: "",
        type_business_id: "",
        founding_date: "",
        logo: "",
        include_salary_tax: "",
      },
    };
  },
  methods: {
    focusNextInput(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    PreviewImage: function (event) {
      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be jpeg,png"
        );
      } else if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append("imageFile", imageFile);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.data.logo = res.data.filename;
        }
      });
    },
    saveChange() {
      const formData = new FormData();
      formData.append("name", this.data.name);
      formData.append("phone", this.data.phone);
      formData.append("email", this.data.email);
      formData.append("address", this.data.address);
      formData.append("type_business_id", this.data.type_business_id);
      formData.append("founding_date", this.data.founding_date);
      formData.append("logo", this.data.logo);
      formData.append("charge_vat", this.data.charge_vat);
      formData.append("include_salary_tax", "no");
      formData.append("edit_clock_in_out", this.data.edit_clock_in_out)
      this.$axios
        .post(`admin/company`, formData)
        .then((res) => {
          if (res.data.success === true) 
          {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
              this.resetForm();
              this.$router.push({ name: "company.index" });
            }, 300);
          }
          this.btnLoading = false;
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
            this.btnLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
    fetchBusinessType() {
      this.$axios.get(`admin/type-business`).then((res) => {
        this.businessType = res.data.data;
      });
    },
  },
  created() {
    this.fetchBusinessType();
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 0 10px 0 10px;
  margin-right: auto;
  margin-left: auto;
}

.from-content {
  width: 100%;
  height: 100%;
  padding: 40px 50px;
  display: flex;
  justify-content: center;

  .from {
    width: 600px;
    height: 80vh;
  }
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
</style>
